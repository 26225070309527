<template>
  <svg viewBox="0 0 20 21">
    <path
      d="M4.16667 9.66699H10.8333V11.3337H4.16667V13.8337L0 10.5003L4.16667 7.16699V9.66699ZM3.33333 15.5003H5.59C6.55224 16.3489 7.73891 16.9019 9.00761 17.0928C10.2763 17.2837 11.5731 17.1044 12.7425 16.5766C13.9119 16.0487 14.9041 15.1946 15.6001 14.1168C16.296 13.039 16.6663 11.7833 16.6663 10.5003C16.6663 9.21734 16.296 7.96161 15.6001 6.88382C14.9041 5.80602 13.9119 4.95194 12.7425 4.42408C11.5731 3.89621 10.2763 3.71697 9.00761 3.90788C7.73891 4.09878 6.55224 4.65171 5.59 5.50033H3.33333C4.10888 4.46464 5.11528 3.62409 6.27256 3.04545C7.42984 2.46681 8.70612 2.16602 10 2.16699C14.6025 2.16699 18.3333 5.89783 18.3333 10.5003C18.3333 15.1028 14.6025 18.8337 10 18.8337C8.70612 18.8346 7.42984 18.5339 6.27256 17.9552C5.11528 17.3766 4.10888 16.536 3.33333 15.5003V15.5003Z"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
};
</script>
